body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.card {
  box-shadow: none !important;
}

.pcoded-navbar {
  box-shadow: none !important;
}

@media (max-width: 768px) {
  .search-field {
    margin-left: -50px;
    width: 100% !important;
  }

  .search-field input {
    width: 60% !important;
    margin-left: 0;
  }

  .search-field ul {
    padding-left: 0;
  }

  .mobile-dropdown {
    left: -230px !important;
  }
}
